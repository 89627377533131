import React, { useState } from 'react';
import { graphql } from 'gatsby';

import Head from '@components/Head';

import { LayoutContainer } from '@components/Layout';
import { WelcomePromoBar } from '@global/data/promobarsData';
import ResourcesCenterHero from '@sections/ResourcesCenterHero/ResourcesCenterHero';
import QuickAccessPanel from '@sections/QuickAccessPanel/QuickAccessPanel';
import { GlobalWrapper } from '@global/styles/grid';
import ArticleList from '@sections/ArticlesList/ArticleList';
import ArticleFilter from '@sections/ArticlesList/components/ArticleFilter/ArticleFilter';
import { InActionContainer, QuickAccessContainer } from './styles';

const ResourcesCenter = ({ data }: any) => {
  const [filterApplied, setFilterApplied] = useState<string[]>([]);
  const [renderFilterPage, setRenderFilterPage] = useState(false);
  const landingLayoutData = data?.cms?.landing?.data?.attributes?.Layout;
  const quickAccessPanelData = data?.cms?.inActionQuickPanelAccesses?.data;
  const topicsFilter = data?.cms?.blogTopics?.data;
  const rolesFilter = data?.cms?.blogRoles?.data;
  const careerClustersFilter = data?.cms?.blogCareerClusters?.data;
  const heroData = {
    materials: data?.cms.heroMaterials.data,
    trainings: data?.cms.heroTrainings.data,
  };
  const materials = data?.cms.materials.data;
  const webinars = data?.cms.webinars.data;

  if (heroData.materials?.length + heroData.trainings?.length > 3) {
    if (heroData.materials?.length > 0 && heroData.trainings?.length > 0) {
      if (heroData.materials?.length > 2) {
        heroData.materials.pop();
      }
      if (heroData.trainings?.length > 2) {
        heroData.trainings.pop();
        if (heroData.materials?.length > 1) {
          heroData.trainings.pop();
        }
      }
    }
  }

  const handleMaterialsCarrousel = () => {
    const sortedMaterialsViaPublicationDate = materials.sort((material1, material2) => {
      return new Date(material1.attributes.Publication_Date) <
        new Date(material2.attributes.Publication_Date)
        ? 1
        : -1;
    });

    if (materials.length > 5) {
      return sortedMaterialsViaPublicationDate.slice(0, 5);
    }
    return sortedMaterialsViaPublicationDate;
  };

  const handelFilterApplied = (value: string) => {
    if (filterApplied.find((filter) => filter === value)) {
      setFilterApplied(filterApplied.filter((filter) => filter !== value));
    } else {
      setRenderFilterPage(true);
      setFilterApplied([...filterApplied, value]);
    }
  };

  const handleClearFilters = () => {
    setFilterApplied([]);
  };

  return (
    <main>
      <Head
        title="Your College & Career Readiness Journey | Pathful"
        description="Pathful is the complete college and career readiness system that helps students decide what they want to be and how to prepare for life beyond the classroom."
        tag="classroom, career, internship, learning, curriculum, career exploration, discovery, planning, path, work-based learning, career readiness, education outreach, real-world learning, course planner, graduation plans, life skills, digital lessons, virtual lessons, virtual job shadow, job shadow, job shadowing, career videos, career technical education, career placement, K12 internship opportunities"
        url="https://pathful.com/"
        imageUrl="https://pathful.com/pathful_preview.png"
      />
      <LayoutContainer
        promobarConfig={WelcomePromoBar}
        activeLogo={landingLayoutData?.Topbar_Scroll_Logo}
        topbarBorderColor={landingLayoutData?.Topbar_Details_Color}
        topbarMenuId={landingLayoutData?.Topbar_Menu}
        footerMenuId={landingLayoutData?.Footer_Menu}
        floatingTopbar
      >
        {renderFilterPage ? (
          <GlobalWrapper>
            <ArticleFilter
              topicsFilter={topicsFilter}
              rolesFilter={rolesFilter}
              careersFilter={careerClustersFilter}
              materials={materials}
              trainings={webinars}
              filterApplied={filterApplied}
              handelFilterApplied={handelFilterApplied}
              handleClearFilters={handleClearFilters}
            />
          </GlobalWrapper>
        ) : (
          <>
            <ResourcesCenterHero data={heroData} />
            <GlobalWrapper>
              <InActionContainer>
                <QuickAccessContainer>
                  <QuickAccessPanel data={quickAccessPanelData} />
                </QuickAccessContainer>
                <ArticleList
                  topicsFilter={topicsFilter}
                  rolesFilter={rolesFilter}
                  careersFilter={careerClustersFilter}
                  materials={handleMaterialsCarrousel()}
                  trainings={webinars}
                  filterApplied={filterApplied}
                  handelFilterApplied={handelFilterApplied}
                />
              </InActionContainer>
            </GlobalWrapper>
          </>
        )}
      </LayoutContainer>
    </main>
  );
};

export const pageQuery = graphql`
  query ($id: ID!) {
    cms {
      landing(id: $id) {
        data {
          attributes {
            Layout {
              Topbar_Details_Color
              Topbar_Scroll_Logo {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
              Topbar_Menu
              Footer_Menu
            }
          }
        }
      }
    }
    cms {
      webinars(pagination: { limit: -1 }, sort: "publishedAt") {
        data {
          attributes {
            Title
            Subtitle
            Schedule
            Small_Image {
              data {
                attributes {
                  alternativeText
                  url
                  mime
                  urlJSON
                }
              }
            }
            Roles {
              data {
                attributes {
                  Role
                }
              }
            }
            Topics {
              data {
                attributes {
                  Topic
                }
              }
            }
            Pathful_Modules {
              data {
                attributes {
                  Pathful_Module
                }
              }
            }
          }
        }
      }
      materials: blogs(pagination: { limit: -1 }) {
        data {
          attributes {
            Author
            Title
            Subtitle
            Time_To_Read
            Publication_Date
            Small_Image {
              data {
                attributes {
                  alternativeText
                  url
                  mime
                  urlJSON
                }
              }
            }
            Roles {
              data {
                attributes {
                  Role
                }
              }
            }
            Topics {
              data {
                attributes {
                  Topic
                }
              }
            }
            Pathful_Modules {
              data {
                attributes {
                  Pathful_Module
                }
              }
            }
          }
        }
      }
      heroTrainings: webinars(
        filters: { In_Action_Panel: { eq: true } }
        pagination: { limit: 3 }
      ) {
        data {
          attributes {
            Title
            Schedule
            Subtitle
            Small_Image {
              data {
                attributes {
                  alternativeText
                  url
                  mime
                  urlJSON
                }
              }
            }
          }
        }
      }
      heroMaterials: blogs(filters: { In_Action_Panel: { eq: true } }, pagination: { limit: 3 }) {
        data {
          attributes {
            Title
            Subtitle
            Time_To_Read
            Publication_Date
            Small_Image {
              data {
                attributes {
                  alternativeText
                  url
                  mime
                  urlJSON
                }
              }
            }
          }
        }
      }
    }
    cms {
      inActionQuickPanelAccesses(pagination: { limit: -1 }) {
        data {
          attributes {
            Quick_Panel_Access_CTA_Link
            Quick_Panel_Access_CTA_Text
            Quick_Panel_Access_Subtitle
            Quick_Panel_Access_Title
            Quick_Panel_Access_Icon {
              data {
                attributes {
                  alternativeText
                  url
                  mime
                  urlJSON
                }
              }
            }
          }
        }
      }
    }
    cms {
      blogCareerClusters {
        data {
          attributes {
            Pathful_Module
          }
        }
      }
    }
    cms {
      blogRoles {
        data {
          attributes {
            Role
          }
        }
      }
    }
    cms {
      blogTopics {
        data {
          attributes {
            Topic
          }
        }
      }
    }
  }
`;

export default ResourcesCenter;
