import React, { useCallback, useEffect, useState } from 'react';
import { GlobalWrapper } from '@global/styles/grid';
import { PATHS } from '@global/constants/urls';
import { SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import {
  Container,
  FiltersContainer,
  FilterWrapper,
  TitleFilterContainer,
  FilterTitle,
  IconContainer,
  FilterInput,
  CheckboxInput,
  FilterButton,
  FilterItemsList,
  ArticlesContainer,
  ArticleSection,
  Carrousel,
  ArticleViewMore,
  ArticleTitle,
  ArrowNavigationNext,
  ArrowNavigationPrev,
  FilterContainer,
  CarrouselContainer,
} from './articleList.styles';
import ArrowIcon from './assets/ArrowIcon';
import { ArticleListProps } from './articleList.types';
import CarrouselIcon from './assets/CarrouselIcon';
import TrainingCard from './components/TrainingCard/TrainingCard';
import MaterialCard from './components/MaterialCard/MaterialCard';

const ArticleList = ({
  topicsFilter,
  careersFilter,
  rolesFilter,
  trainings,
  materials,
  filterApplied,
  handelFilterApplied,
}: ArticleListProps) => {
  const [careerFilterOpen, setCareerFilterOpen] = useState<boolean>(false);
  const [topicFilterOpen, setTopicFilterOpen] = useState<boolean>(false);
  const [roleFilterOpen, setRoleFilterOpen] = useState<boolean>(false);
  const [renderFilters, setRenderFilters] = useState(false);
  const [swiperRef, setSwiperRef] = useState<SwiperClass>();
  const [swiperRefTraining, setSwiperRefTraining] = useState<SwiperClass>();
  const [isNextArrowRender, setIsNextArrowRender] = useState(true);
  const [isPrevArrowRender, setIsPrevArrowRender] = useState(false);
  const [isPrevArrowRenderTraining, setIsPrevArrowRenderTraining] = useState(false);
  const [isNextArrowRenderTraining, setIsNextArrowRenderTraining] = useState(true);
  const [renderTooltipMaterial, setRenderTooltipMaterial] = useState<string[]>([]);
  const [renderTooltipTraining, setRenderTooltipTraining] = useState<string[]>([]);
  SwiperCore.use([Navigation, Pagination]);
  const handleTrainingsCarrousel = () => {
    if (trainings.length > 5) {
      return trainings.slice(trainings.length - 5, trainings.length);
    }
    return trainings;
  };

  const handleRenderArrows = useCallback(() => {
    if (swiperRef && swiperRef.activeIndex > 0) {
      if (swiperRef.activeIndex >= 2 && swiperRef.isEnd) {
        setIsNextArrowRender(false);
      } else {
        setIsNextArrowRender(true);
      }
      setIsPrevArrowRender(true);
    } else {
      if (swiperRef.isEnd) {
        setIsNextArrowRender(false);
      } else {
        setIsNextArrowRender(true);
      }
      setIsPrevArrowRender(false);
    }
  }, [swiperRef]);

  const handleRenderArrowsTrainings = useCallback(() => {
    if (swiperRefTraining && swiperRefTraining.activeIndex > 0) {
      if (swiperRefTraining.activeIndex >= 2 && swiperRefTraining.isEnd) {
        setIsNextArrowRenderTraining(false);
      } else {
        setIsNextArrowRenderTraining(true);
      }
      setIsPrevArrowRenderTraining(true);
    } else {
      if (swiperRefTraining.isEnd) {
        setIsNextArrowRenderTraining(false);
      } else {
        setIsNextArrowRenderTraining(true);
      }
      setIsPrevArrowRenderTraining(false);
    }
  }, [swiperRefTraining]);

  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev();
    handleRenderArrows();
  }, [handleRenderArrows, swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
    handleRenderArrows();
  }, [handleRenderArrows, swiperRef]);

  const handlePreviousTraining = useCallback(() => {
    swiperRefTraining?.slidePrev();
    handleRenderArrowsTrainings();
  }, [handleRenderArrowsTrainings, swiperRefTraining]);

  const handleNextTraining = useCallback(() => {
    swiperRefTraining?.slideNext();
    handleRenderArrowsTrainings();
  }, [handleRenderArrowsTrainings, swiperRefTraining]);

  useEffect(() => {
    const isEllipsisActive = (element) => {
      return element.offsetHeight + 2 < element.scrollHeight;
    };

    const materialsTitle = document.querySelectorAll('.material-title');
    const renderTitleTooltip: string[] = [];
    materialsTitle.forEach((el) => {
      if (isEllipsisActive(el)) {
        renderTitleTooltip.push(el.innerText);
      }
    });
    setRenderTooltipMaterial(renderTitleTooltip);
    const trainingsTitle = document.querySelectorAll('.training-title');
    const renderTrainingsTitleTooltip: string[] = [];
    trainingsTitle.forEach((el) => {
      if (isEllipsisActive(el)) {
        renderTrainingsTitleTooltip.push(el.innerText);
      }
    });
    setRenderTooltipTraining(renderTrainingsTitleTooltip);
  }, []);

  return (
    <GlobalWrapper>
      <Container>
        <FiltersContainer>
          <FilterButton onClick={() => setRenderFilters(!renderFilters)}>Filters</FilterButton>
          <FilterWrapper renderFilters={renderFilters}>
            {(careersFilter.length > 0 || topicsFilter.length > 0 || rolesFilter.length > 0) && (
              <TitleFilterContainer>
                <FilterTitle>Filters resources:</FilterTitle>
              </TitleFilterContainer>
            )}
            {careersFilter.length > 0 && (
              <FilterContainer>
                <TitleFilterContainer
                  alignCenter
                  onClick={() => {
                    setCareerFilterOpen(!careerFilterOpen);
                    setTopicFilterOpen(false);
                    setRoleFilterOpen(false);
                  }}
                >
                  <FilterTitle className="filter-title" boldText>
                    Pathful Module
                  </FilterTitle>
                  <IconContainer className="icon-container" isOpen={careerFilterOpen}>
                    <ArrowIcon />
                  </IconContainer>
                </TitleFilterContainer>
                <FilterItemsList>
                  {careerFilterOpen &&
                    careersFilter.map((career, index) => (
                      <FilterInput
                        key={career.attributes.Pathful_Module}
                        lastChild={careersFilter.length - 1 === index}
                      >
                        <CheckboxInput
                          name={career.attributes.Pathful_Module}
                          value={career.attributes.Pathful_Module}
                          checked={filterApplied.includes(career.attributes.Pathful_Module)}
                          onChange={() => handelFilterApplied(career.attributes.Pathful_Module)}
                          type="checkbox"
                        />
                        {career.attributes.Pathful_Module}
                      </FilterInput>
                    ))}
                </FilterItemsList>
              </FilterContainer>
            )}
            {topicsFilter.length > 0 && (
              <FilterContainer>
                <TitleFilterContainer
                  alignCenter
                  onClick={() => {
                    setTopicFilterOpen(!topicFilterOpen);
                    setRoleFilterOpen(false);
                    setCareerFilterOpen(false);
                  }}
                >
                  <FilterTitle className="filter-title" boldText>
                    Topics
                  </FilterTitle>
                  <IconContainer className="icon-container" isOpen={topicFilterOpen}>
                    <ArrowIcon />
                  </IconContainer>
                </TitleFilterContainer>
                <FilterItemsList>
                  {topicFilterOpen &&
                    topicsFilter.map((topic, index) => (
                      <FilterInput
                        key={topic.attributes.Topic}
                        lastChild={topicsFilter.length - 1 === index}
                      >
                        <CheckboxInput
                          name={topic.attributes.Topic}
                          value={topic.attributes.Topic}
                          checked={filterApplied.includes(topic.attributes.Topic)}
                          onChange={() => handelFilterApplied(topic.attributes.Topic)}
                          type="checkbox"
                        />
                        {topic.attributes.Topic}
                      </FilterInput>
                    ))}
                </FilterItemsList>
              </FilterContainer>
            )}
            {rolesFilter.length > 0 && (
              <FilterContainer>
                <TitleFilterContainer
                  alignCenter
                  onClick={() => {
                    setRoleFilterOpen(!roleFilterOpen);
                    setCareerFilterOpen(false);
                    setTopicFilterOpen(false);
                  }}
                >
                  <FilterTitle className="filter-title" boldText>
                    Role
                  </FilterTitle>
                  <IconContainer className="icon-container" isOpen={roleFilterOpen}>
                    <ArrowIcon />
                  </IconContainer>
                </TitleFilterContainer>
                <FilterItemsList>
                  {roleFilterOpen &&
                    rolesFilter.map((role, index) => (
                      <FilterInput
                        key={role.attributes.Role}
                        lastChild={rolesFilter.length - 1 === index}
                      >
                        <CheckboxInput
                          name={role.attributes.Role}
                          value={role.attributes.Role}
                          checked={filterApplied.includes(role.attributes.Role)}
                          onChange={() => handelFilterApplied(role.attributes.Role)}
                          type="checkbox"
                        />
                        {role.attributes.Role}
                      </FilterInput>
                    ))}
                </FilterItemsList>
              </FilterContainer>
            )}
          </FilterWrapper>
        </FiltersContainer>
        {materials.length > 0 && (
          <>
            <ArticleSection>
              <ArticleTitle>Blog</ArticleTitle>
              <ArticleViewMore href={PATHS.MATERIAL}>View more</ArticleViewMore>
            </ArticleSection>
            <ArticlesContainer>
              <ArrowNavigationNext
                className=".swiper-button-next"
                onClick={handleNext}
                render={isNextArrowRender}
              >
                <CarrouselIcon />
              </ArrowNavigationNext>
              <ArrowNavigationPrev
                render={isPrevArrowRender}
                className=".swiper-button-prev"
                onClick={handlePrevious}
              >
                <CarrouselIcon />
              </ArrowNavigationPrev>
              <CarrouselContainer>
                <Carrousel
                  slidesPerView={1.3}
                  spaceBetween={26}
                  navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                  }}
                  onSwiper={setSwiperRef}
                  className="mySwiper"
                  watchOverflow
                  breakpoints={{
                    1280: {
                      slidesPerView: 2.8,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                    769: {
                      slidesPerView: 2.1,
                    },
                  }}
                >
                  {materials.map((material) => (
                    <SwiperSlide key={material.attributes.Title} className="swiper-slide">
                      <MaterialCard material={material} renderTooltip={renderTooltipMaterial} />
                    </SwiperSlide>
                  ))}
                </Carrousel>
              </CarrouselContainer>
            </ArticlesContainer>
          </>
        )}
        {trainings.length > 0 && (
          <>
            <ArticleSection>
              <ArticleTitle>Training</ArticleTitle>
              <ArticleViewMore href={PATHS.TRAINING}>View more</ArticleViewMore>
            </ArticleSection>
            <ArticlesContainer>
              <ArrowNavigationNext
                className=".swiper-button-next-training"
                onClick={handleNextTraining}
                render={isNextArrowRenderTraining}
              >
                <CarrouselIcon />
              </ArrowNavigationNext>
              <ArrowNavigationPrev
                render={isPrevArrowRenderTraining}
                className=".swiper-button-prev-training"
                onClick={handlePreviousTraining}
              >
                <CarrouselIcon />
              </ArrowNavigationPrev>
              <CarrouselContainer>
                <Carrousel
                  slidesPerView={1.3}
                  spaceBetween={26}
                  className="mySwiper"
                  navigation={{
                    nextEl: '.swiper-button-next-training',
                    prevEl: '.swiper-button-prev-training',
                  }}
                  onSwiper={setSwiperRefTraining}
                  breakpoints={{
                    1280: {
                      slidesPerView: 2.8,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                    769: {
                      slidesPerView: 2.1,
                    },
                  }}
                >
                  {handleTrainingsCarrousel().map((training) => (
                    <SwiperSlide key={training.attributes.Title} className="swiper-slide">
                      <TrainingCard training={training} renderTooltip={renderTooltipTraining} />
                    </SwiperSlide>
                  ))}
                </Carrousel>
              </CarrouselContainer>
            </ArticlesContainer>
          </>
        )}
      </Container>
    </GlobalWrapper>
  );
};

export default ArticleList;
