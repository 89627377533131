import React, { useEffect, useState } from 'react';
import { GlobalWrapper } from '@global/styles/grid';
import ArrowIcon from '@sections/ArticlesList/assets/ArrowIcon';
import { ArticleType } from '@sections/ResourcesCenterHero/ResourcesCenterHero.types';
import RemoveIcon from '@sections/ArticlesList/assets/RemoveIcon';
import NoResultFilter from '@components/NoResultFilter/NoResultFilter';
import { ArticleFilterProps } from './articleFilter.types';
import SearchIcon from '../../assets/search-icon.svg';
import {
  ArticleContainer,
  ArticleWrapper,
  BoxContainer,
  CheckboxInput,
  ClearFilter,
  RemoveIconContainer,
  Container,
  FilterButton,
  FilterContainer,
  FilterInput,
  FilterTitle,
  FilterWrapper,
  IconContainer,
  ResultContainer,
  ResultText,
  SearchContainer,
  SearchImage,
  SearchInput,
  Tag,
  TagsContainer,
  TitleFilterContainer,
} from './articleFilter.styles';
import ArticleCard from '../MaterialCard/MaterialCard';
import TrainingCard from '../TrainingCard/TrainingCard';

const ArticleFilter = ({
  materials,
  careersFilter,
  filterApplied,
  handelFilterApplied,
  rolesFilter,
  topicsFilter,
  trainings,
  handleClearFilters,
}: ArticleFilterProps) => {
  const [careerFilterOpen, setCareerFilterOpen] = useState<boolean>(false);
  const [topicFilterOpen, setTopicFilterOpen] = useState<boolean>(false);
  const [roleFilterOpen, setRoleFilterOpen] = useState<boolean>(false);
  const [renderFilters, setRenderFilters] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [confirmSearchInput, setConfirmSearchInput] = useState('');
  const [renderArticles, setRenderArticles] = useState<any[]>([]);
  const [renderTooltipMaterial, setRenderTooltipMaterial] = useState<string[]>([]);
  const [renderTooltipTraining, setRenderTooltipTraining] = useState<string[]>([]);

  const originalPublications = () => {
    const publications: any[] = [];
    trainings.forEach((training) => {
      publications.push({ ...training, type: ArticleType.Training });
    });
    materials.forEach((material) => {
      publications.push({ ...material, type: ArticleType.Material });
    });
    // shuffle array
    return publications
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
  };

  const handleSearch = (arrayArticles: any[]) => {
    if (confirmSearchInput !== '') {
      setRenderArticles(
        arrayArticles.filter((arrayArticle: any) => {
          return arrayArticle.attributes.Title.toLowerCase().includes(
            confirmSearchInput.toLowerCase(),
          );
        }),
      );
    } else {
      setRenderArticles([...new Set(arrayArticles)]);
    }
  };

  const handleFilterArticles = (arrayArticles: any[]) => {
    const articlesToRender: any[] = [];
    arrayArticles.forEach((article) => {
      if (article.attributes.Topics.data.length > 0) {
        article.attributes.Topics.data.forEach((topic: { attributes: { Topic: string } }) => {
          if (filterApplied.includes(topic.attributes.Topic)) {
            articlesToRender.push(article);
          }
        });
      }
      if (article.attributes.Roles.data.length > 0) {
        article.attributes.Roles.data.forEach((role: { attributes: { Role: string } }) => {
          if (filterApplied.includes(role.attributes.Role)) {
            articlesToRender.push(article);
          }
        });
      }
      if (article.attributes.Pathful_Modules.data.length > 0) {
        article.attributes.Pathful_Modules.data.forEach(
          (career: { attributes: { Pathful_Module: string } }) => {
            if (filterApplied.includes(career.attributes.Pathful_Module)) {
              articlesToRender.push(article);
            }
          },
        );
      }
    });

    return articlesToRender;
  };

  useEffect(() => {
    if (filterApplied.length > 0) {
      const articlesToRender = handleFilterArticles(originalPublications());
      handleSearch(articlesToRender);
    } else if (confirmSearchInput !== '') {
      handleSearch(originalPublications());
    }
    if (filterApplied.length === 0 && confirmSearchInput === '') {
      setRenderArticles(originalPublications());
    }
  }, [filterApplied, confirmSearchInput]);

  useEffect(() => {
    const input = document.getElementById('searchInput');
    if (input) {
      input.onkeydown = (event) => {
        if (event.code === 'Enter') {
          event.preventDefault();
          setConfirmSearchInput(event.target?.value);
        }
      };
    }
    const isEllipsisActive = (element) => {
      return element.offsetHeight + 2 < element.scrollHeight;
    };

    const materialsTitle = document.querySelectorAll('.material-title');
    const renderTitleTooltip: string[] = [];
    materialsTitle.forEach((el) => {
      if (isEllipsisActive(el)) {
        renderTitleTooltip.push(el.innerText);
      }
    });
    setRenderTooltipMaterial(renderTitleTooltip);
    const trainingsTitle = document.querySelectorAll('.training-title');
    const renderTrainingsTitleTooltip: string[] = [];
    trainingsTitle.forEach((el) => {
      if (isEllipsisActive(el)) {
        renderTrainingsTitleTooltip.push(el.innerText);
      }
    });
    setRenderTooltipTraining(renderTrainingsTitleTooltip);
  }, []);

  return (
    <GlobalWrapper>
      <Container>
        <FilterContainer>
          <FilterButton onClick={() => setRenderFilters(!renderFilters)}>Filters</FilterButton>
          <SearchContainer>
            <SearchInput
              id="searchInput"
              placeholder="Search"
              type="text"
              onChange={(event) => setSearchInput(event.target.value)}
            />
            <SearchImage onClick={() => setConfirmSearchInput(searchInput)} src={SearchIcon} />
          </SearchContainer>
          <FilterWrapper renderFilters={renderFilters}>
            {(careersFilter.length > 0 || topicsFilter.length > 0 || rolesFilter.length > 0) && (
              <TitleFilterContainer>
                <FilterTitle>Filters</FilterTitle>
                <ClearFilter onClick={handleClearFilters}>Clear Filters</ClearFilter>
              </TitleFilterContainer>
            )}
            {careersFilter.length > 0 && (
              <>
                <TitleFilterContainer
                  isFilterOpen={careerFilterOpen}
                  alignCenter
                  onClick={() => setCareerFilterOpen(!careerFilterOpen)}
                >
                  <FilterTitle className="filter-title">Pathful Module</FilterTitle>
                  <IconContainer className="icon-container" isOpen={careerFilterOpen}>
                    <ArrowIcon />
                  </IconContainer>
                </TitleFilterContainer>
                {careerFilterOpen &&
                  careersFilter.map((career, index) => (
                    <FilterInput
                      key={career.attributes.Pathful_Module}
                      lastChild={careersFilter.length - 1 === index}
                    >
                      <CheckboxInput
                        name={career.attributes.Pathful_Module}
                        value={career.attributes.Pathful_Module}
                        checked={filterApplied.includes(career.attributes.Pathful_Module)}
                        onChange={() => handelFilterApplied(career.attributes.Pathful_Module)}
                        type="checkbox"
                      />
                      {career.attributes.Pathful_Module}
                    </FilterInput>
                  ))}
              </>
            )}
            {topicsFilter.length > 0 && (
              <>
                <TitleFilterContainer
                  isFilterOpen={topicFilterOpen}
                  alignCenter
                  onClick={() => setTopicFilterOpen(!topicFilterOpen)}
                >
                  <FilterTitle className="filter-title">Topics</FilterTitle>
                  <IconContainer className="icon-container" isOpen={topicFilterOpen}>
                    <ArrowIcon />
                  </IconContainer>
                </TitleFilterContainer>
                {topicFilterOpen &&
                  topicsFilter.map((topic, index) => (
                    <FilterInput
                      key={topic.attributes.Topic}
                      lastChild={topicsFilter.length - 1 === index}
                    >
                      <CheckboxInput
                        name={topic.attributes.Topic}
                        value={topic.attributes.Topic}
                        checked={filterApplied.includes(topic.attributes.Topic)}
                        onChange={() => handelFilterApplied(topic.attributes.Topic)}
                        type="checkbox"
                      />
                      {topic.attributes.Topic}
                    </FilterInput>
                  ))}
              </>
            )}
            {rolesFilter.length > 0 && (
              <>
                <TitleFilterContainer
                  isFilterOpen={roleFilterOpen}
                  alignCenter
                  onClick={() => setRoleFilterOpen(!roleFilterOpen)}
                >
                  <FilterTitle className="filter-title">Role</FilterTitle>
                  <IconContainer className="icon-container" isOpen={roleFilterOpen}>
                    <ArrowIcon />
                  </IconContainer>
                </TitleFilterContainer>
                {roleFilterOpen &&
                  rolesFilter.map((role, index) => (
                    <FilterInput
                      key={role.attributes.Role}
                      lastChild={rolesFilter.length - 1 === index}
                    >
                      <CheckboxInput
                        name={role.attributes.Role}
                        value={role.attributes.Role}
                        checked={filterApplied.includes(role.attributes.Role)}
                        onChange={() => handelFilterApplied(role.attributes.Role)}
                        type="checkbox"
                      />
                      {role.attributes.Role}
                    </FilterInput>
                  ))}
              </>
            )}
          </FilterWrapper>
        </FilterContainer>
        <ArticleContainer>
          {filterApplied.length > 0 && (
            <ResultContainer>
              <ResultText>Results for:</ResultText>
              <TagsContainer>
                {filterApplied.map((filter) => (
                  <Tag>
                    {filter}
                    <RemoveIconContainer onClick={() => handelFilterApplied(filter)}>
                      <RemoveIcon />
                    </RemoveIconContainer>
                  </Tag>
                ))}
              </TagsContainer>
            </ResultContainer>
          )}
          <ArticleWrapper>
            {renderArticles.length > 0 ? (
              renderArticles.map((article) =>
                article.type === ArticleType.Material ? (
                  <BoxContainer>
                    <ArticleCard material={article} renderTooltip={renderTooltipMaterial} />
                  </BoxContainer>
                ) : (
                  <BoxContainer>
                    <TrainingCard training={article} renderTooltip={renderTooltipTraining} />
                  </BoxContainer>
                ),
              )
            ) : (
              <NoResultFilter />
            )}
          </ArticleWrapper>
        </ArticleContainer>
      </Container>
    </GlobalWrapper>
  );
};

export default ArticleFilter;
