import { COLORS } from '@global/styles/colors';
import { FONT } from '@global/styles/default';
import { DESKTOP_1024, DESKTOP_1280, MOBILE_575, TABLET_768 } from '@global/styles/sizes';
import styled, { css } from 'styled-components';
import { ArticleType } from './ResourcesCenterHero.types';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #000000;
`;

export const TitleContainer = styled.h1`
  margin: 19px 0 0px;
  font-family: ${FONT.ulmGrotesk};
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  color: ${COLORS.white};
`;

export const SubtitleContainer = styled.p`
  font-family: 'Poppins';
  font-size: 16px;
  line-height: 26px;
  color: ${COLORS.white};
`;

export const ArticlesContainer = styled.div`
  margin: 33px 0 50px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: repeat(2, 132px);
  column-gap: 26px;
  row-gap: 21px;

  @media (max-width: ${DESKTOP_1024}px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 115px;
  }

  @media (max-width: ${TABLET_768}px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 145px 145px;
  }
`;

export const ArticleTitle = styled.div<{ isFirstArticle: boolean }>`
  height: ${({ isFirstArticle }) => (isFirstArticle ? '64px' : '50px')};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: ${({ isFirstArticle }) => (isFirstArticle ? '28px' : '20px')};
  line-height: ${({ isFirstArticle }) => (isFirstArticle ? '32px' : '24px')};
  color: #000000;
`;

export const ArticleSubtitle = styled.div`
  height: 41px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Poppins';
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.resolutionBlue};
`;

export const ArticlePublicationDate = styled.div`
  margin-top: 4px;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
  line-height: 11px;
  color: ${COLORS.resolutionBlue};
`;

export const DataContainerArticle = styled.div<{ isFirstArticle: boolean }>`
  width: 50%;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 8px 0 0 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
  position: relative;
  background-color: ${COLORS.white};

  @media (max-width: ${DESKTOP_1024}px) {
    width: 54%;
  }

  @media (max-width: ${TABLET_768}px) {
    ${({ isFirstArticle }) =>
      isFirstArticle &&
      css`
        width: 100%;
        border-radius: 0 0 8px 8px;
      `};
  }
`;

export const ArticleWrapper = styled.a<{ isFirstArticle: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  box-shadow: 0px 0px 0px 1px ${COLORS.white};
  border-radius: 8px;

  ${({ isFirstArticle }) =>
    isFirstArticle
      ? css`
          grid-column-start: 1;
          grid-row-start: 1;
          grid-row-end: 3;
        `
      : css`
          grid-column-start: 2;
        `}

  &:hover {
    .data-container {
      background-color: #e2e8f0;
    }
  }

  @media (max-width: ${DESKTOP_1024}px) {
    ${({ isFirstArticle }) =>
      isFirstArticle
        ? css`
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-end: 1;
          `
        : css`
            grid-column-start: auto;
          `}
  }

  @media (max-width: ${TABLET_768}px) {
    grid-column-start: auto;
    grid-column-end: auto;
    grid-row-end: auto;
    flex-direction: ${({ isFirstArticle }) => (isFirstArticle ? 'column-reverse' : 'row')};
  }
`;

export const ImageContainer = styled.div<{ isFirstArticle: boolean }>`
  width: 50%;
  height: 100%;
  border-radius: 0 8px 8px 0;
  position: relative;

  div {
    height: inherit !important;
  }
  img {
    height: 100% !important;
    border-radius: 0 8px 8px 0;
    object-fit: cover;
    object-position: left;
  }

  @media (max-width: ${TABLET_768}px) {
    ${({ isFirstArticle }) =>
      isFirstArticle &&
      css`
        width: 100%;
        border-radius: 8px 8px 0 0;
        img {
          border-radius: 8px 8px 0 0;
        }
      `};
  }

  @media (max-width: ${MOBILE_575}px) {
    ${({ isFirstArticle }) =>
      !isFirstArticle &&
      css`
        width: 46%;
      `};
  }
`;

export const TriningDate = styled.p`
  margin-left: 5px;
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  line-height: 21px;
  font-weight: normal;
  font-family: 'Poppins';
  color: ${COLORS.resolutionBlue};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: ${DESKTOP_1280}px) {
    min-height: 42px;
  }
`;

export const ArticleData = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 20px;

  @media (max-width: ${TABLET_768}px) {
    margin-top: 15px;
    align-items: flex-start;
    gap: 10px;
  }
`;

export const ArticleTimeToRead = styled.div`
  display: flex;
  font-family: 'Poppins';
  font-size: 16px;
  line-height: 10px;
  color: #000000;
`;

export const Tag = styled.p<{ type: ArticleType; position?: string; isFirstArticle: boolean }>`
  width: ${({ type }) => (type === ArticleType.Material ? '76px' : '110px')};
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: ${({ position }) => position || 'absolute'};
  top: 0;
  right: 0;
  z-index: 1;
  background-color: ${({ type }) =>
    type === ArticleType.Material ? COLORS.Green : COLORS.violetRed};
  border-radius: ${({ isFirstArticle }) => (isFirstArticle ? '8px' : '0 8px 0 8px')};
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: ${COLORS.white};
`;

export const ArticleTitleContainer = styled.div`
  height: 46%;
  padding-top: 10px;
  box-sizing: border-box;
  position: relative;

  @media (max-width: ${DESKTOP_1024}px) {
    height: 40%;
    padding-top: 0;
  }

  &:hover {
    .article-title-tooltip {
      display: flex;
    }
  }
`;

export const ArticleTitleTooltip = styled.div`
  width: 100%;
  padding: 10px;
  bottom: 54%;
  left: 10%;
  z-index: 4;
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  text-decoration: none;
  background-color: ${COLORS.white};
`;
