import { COLORS } from '@global/styles/colors';
import { DESKTOP_1280 } from '@global/styles/sizes';
import styled from 'styled-components';

export const MaterialContainer = styled.a`
  width: max-content;
  box-sizing: border-box;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    .material-data {
      background-color: #e2e8f0;
    }
  }

  @media (max-width: ${DESKTOP_1280}px) {
    width: inherit;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 140px;
  position: relative;
  border-radius: 8px 8px 0 0;

  img {
    height: 140px !important;
    border-radius: 8px 8px 0 0;
    object-fit: cover;
    object-position: left;
  }
`;
export const Tag = styled.div`
  width: 76px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background-color: ${COLORS.Green};
  border-radius: 0 8px 0 8px;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: ${COLORS.white};
`;

export const DataContainer = styled.div`
  padding: 16px 16px 13px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 0 0 8px 8px;
  border: 1px solid #d5d5d5;
  border-top: none;
`;
export const TitleContainer = styled.div`
  font-family: 'Poppins';
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  color: #000000;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SubtitleContainer = styled.div`
  min-height: 60px;
  font-family: 'Poppins';
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.resolutionBlue};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MoreInfoContainer = styled.div`
  margin-top: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
`;

export const MoreInfoText = styled.div`
  min-height: 42px;
  font-family: 'Poppins';
  font-size: 16px;
  line-height: 16px;
  color: #252a34;
`;

export const MaterialTitleTooltip = styled.div`
  padding: 10px;
  bottom: 54%;
  left: 10%;
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  text-decoration: none;
  background-color: ${COLORS.white};
`;

export const MaterialTitleContainer = styled.div`
  min-height: 52px;
  padding-top: 10px;
  position: relative;

  &:hover {
    .material-title-tooltip {
      display: flex;
    }
  }
`;
