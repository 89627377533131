import React, { SVGProps } from 'react';

const RemoveIcon = (props?: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.992"
      height="19.992"
      viewBox="0 0 19.992 19.992"
      {...props}
    >
      <path
        id="icon-close"
        d="M12.012,2a10,10,0,1,0,9.99,10A10,10,0,0,0,12.012,2Zm0,18.992a9,9,0,1,1,8.99-9A9,9,0,0,1,12.012,21Zm.695-9,3.182-3.182a.5.5,0,0,0-.707-.707L12,11.293,8.818,8.111a.5.5,0,0,0-.707.707L11.293,12,8.111,15.182a.5.5,0,1,0,.707.707L12,12.707l3.182,3.182a.5.5,0,0,0,.354.146h0a.5.5,0,0,0,.353-.854Z"
        transform="translate(-2.01 -2.004)"
      />
    </svg>
  );
};

export default RemoveIcon;
