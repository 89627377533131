import React from 'react';
import { CMSParseImage } from '@components/StrapiComponents/ImageParser';
import { transformToSlug } from 'helpers/slugHelper';
import { PATHS } from '@global/constants/urls';

import {
  MaterialContainer,
  ImageContainer,
  DataContainer,
  Tag,
  TitleContainer,
  SubtitleContainer,
  MoreInfoContainer,
  MoreInfoText,
  MaterialTitleTooltip,
  MaterialTitleContainer,
} from './materialCard.styles';
import { MaterialCardProps } from './materialCard.types';

const MaterialCard = ({ material, renderTooltip }: MaterialCardProps) => {
  return (
    <MaterialContainer
      href={`${PATHS.MATERIAL}/${transformToSlug(material.attributes.Title.toLowerCase())}`}
    >
      <ImageContainer>
        <Tag>Blog</Tag>
        {CMSParseImage(material.attributes.Small_Image.data)}
      </ImageContainer>
      <DataContainer className="material-data">
        <MaterialTitleContainer>
          <TitleContainer className="material-title">{material.attributes.Title}</TitleContainer>
          {renderTooltip?.includes(material.attributes.Title) && (
            <MaterialTitleTooltip className="material-title-tooltip">
              {material.attributes.Title}
            </MaterialTitleTooltip>
          )}
        </MaterialTitleContainer>
        <SubtitleContainer>{material.attributes.Subtitle}</SubtitleContainer>
        <MoreInfoContainer>
          <MoreInfoText>{material.attributes.Author}</MoreInfoText>
          <MoreInfoText>{material.attributes.Time_To_Read}</MoreInfoText>
        </MoreInfoContainer>
      </DataContainer>
    </MaterialContainer>
  );
};

export default MaterialCard;
