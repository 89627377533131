import { COLORS } from '@global/styles/colors';
import { FONT } from '@global/styles/default';
import { DESKTOP_1200, DESKTOP_1280, MOBILE_575, TABLET_768 } from '@global/styles/sizes';
import styled, { css } from 'styled-components';
import { Swiper } from 'swiper/react';

export const Container = styled.div`
  width: 71%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @media (max-width: ${DESKTOP_1280}px) {
    width: 67%;
  }

  @media (max-width: ${DESKTOP_1200}px) {
    width: 100%;
  }
`;

export const FiltersContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 40px;

  @media (max-width: ${DESKTOP_1280}px) {
    width: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column: -1 / 1;
  }
  @media (max-width: ${MOBILE_575}px) {
    grid-template-columns: 1fr;
  }
`;

export const FilterWrapper = styled.div<{ renderFilters: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 26px;
  position: relative;

  @media (max-width: ${DESKTOP_1280}px) {
    display: ${({ renderFilters }) => (renderFilters ? 'flex' : 'none')};
    flex-direction: column;
    grid-column: 1/-1;
  }
`;

export const TitleFilterContainer = styled.div<{ alignCenter?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: ${({ alignCenter }) => (alignCenter ? 'center' : 'flex-end')};
  gap: 10px;
  cursor: pointer;

  &:hover {
    .filter-title {
      color: #0e8cb9;
    }
    .icon-container {
      svg {
        path {
          fill: #0e8cb9;
        }
      }
    }
  }
`;

export const FilterTitle = styled.div<{ boldText?: boolean }>`
  font-family: 'Poppins';
  ${({ boldText }) =>
    boldText &&
    css`
      font-weight: bold;
    `}
  font-size: 18px;
  line-height: 27px;
  color: #252a34;
`;

export const IconContainer = styled.div<{ isOpen: boolean }>`
  width: 12px;
  height: 6px;
  display: flex;
  ${({ isOpen }) =>
    !isOpen &&
    css`
      transform: rotate(180deg);
    `}
`;

export const CheckboxInput = styled.input`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid #b9ccd8;
  margin-right: 8px;
  cursor: pointer;
  &:checked {
    accent-color: #0e8cb9;
  }
`;

export const FilterInput = styled.label<{ lastChild?: boolean }>`
  ${({ lastChild }) =>
    lastChild &&
    css`
      border-bottom: unset;
    `}
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: 'Poppins';
  font-size: 13px;
  line-height: 18px;
  color: #4b5366;
  border-bottom: 1px solid #d5d5d5;
`;

export const FilterButton = styled.div`
  width: 230px;
  height: 48px;
  display: none;
  box-sizing: border-box;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-family: 'Poppins';
  font-size: 20px;
  line-height: 30px;
  color: ${COLORS.white};
  background-color: #0e8cb9;
  border: 1px solid #0e8cb9;
  cursor: pointer;
  &:hover {
    color: #0e8cb9;
    background-color: ${COLORS.white};
  }

  @media (max-width: ${DESKTOP_1280}px) {
    display: flex;
  }
  @media (max-width: ${MOBILE_575}px) {
    width: 100%;
  }
`;

export const FilterItemsList = styled.ul`
  width: max-content;
  position: absolute;
  z-index: 10;
  top: 30px;
  background-color: white;
  border-radius: 7px;
  box-shadow: 0px 0px 6px #00000029;
  flex-direction: column;

  :before {
    content: '';
    width: 100%;
    height: 20px;
    display: block;
    position: absolute;
  }
`;

export const ArticlesContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 26px;
  position: relative;
`;

export const ArticleSection = styled.div`
  width: 100%;
  margin: 40px 0 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 15px;
  box-sizing: border-box;
`;

export const ArticleTitle = styled.div`
  font-family: ${FONT.ulmGrotesk};
  font-size: 28px;
  line-height: 36px;
  color: #000000;
  font-weight: bold;
`;

export const ArticleViewMore = styled.a`
  font-family: 'Poppins';
  font-size: 16px;
  line-height: 25px;
  color: #00a8cf;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const CarrouselContainer = styled.div`
  overflow: hidden;
`;

export const Carrousel = styled(Swiper)`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  .swiper-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .swiper-slide {
    width: 100%;
    height: 100%;
    min-width: 258px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: ${DESKTOP_1200}px) {
      min-width: unset;
    }
  }
`;

export const ArrowNavigationNext = styled.div<{ render: boolean }>`
  width: 40px;
  height: 40px;
  display: ${({ render }) => (render ? 'flex' : 'none')};
  position: absolute;
  top: 42%;
  right: -17px;
  z-index: 3;
  cursor: pointer;

  &:hover {
    svg {
      g {
        fill: ${COLORS.Green};
      }
    }
  }
  @media (max-width: ${TABLET_768}px) {
    display: none !important;
  }
`;

export const ArrowNavigationPrev = styled.div<{ render: boolean }>`
  width: 40px;
  height: 40px;
  display: ${({ render }) => (render ? 'flex' : 'none')};
  position: absolute;
  top: 42%;
  left: -17px;
  transform: rotate(180deg);
  z-index: 3;
  cursor: pointer;

  &:hover {
    svg {
      g {
        fill: ${COLORS.Green};
      }
    }
  }

  @media (max-width: ${TABLET_768}px) {
    display: none;
  }
`;

export const FilterContainer = styled.div`
  position: relative;
`;
