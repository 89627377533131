import React, { useEffect, useState } from 'react';
import { GlobalWrapper } from '@global/styles/grid';
import { CMSParseImage } from '@components/StrapiComponents/ImageParser';
import { transformToSlug } from 'helpers/slugHelper';
import { PATHS } from '@global/constants/urls';
import { TrainingSchedule } from '@sections/ArticlesList/components/TrainingCard/trainingCard.styles';
import {
  ArticlesContainer,
  ArticleData,
  ArticlePublicationDate,
  ArticleSubtitle,
  ArticleTimeToRead,
  ArticleTitle,
  Container,
  ImageContainer,
  SubtitleContainer,
  Tag,
  TitleContainer,
  TriningDate,
  ArticleWrapper,
  DataContainerArticle,
  ArticleTitleContainer,
  ArticleTitleTooltip,
} from './resourcesCenterHero.styles';
import { ArticleType, ResourcesCenterHeroProps } from './ResourcesCenterHero.types';

const ResourcesCenterHero = ({ data }: ResourcesCenterHeroProps) => {
  const [renderTooltip, setRenderTooltip] = useState<string[]>([]);

  const articles = [
    data.materials.map((material) => ({ ...material, type: ArticleType.Material })),
    data.trainings.map((training) => ({ ...training, type: ArticleType.Training })),
  ];
  const handleSchedule = (schedule: string) => {
    const scheduleDate = new Date(schedule);
    const scheduleHours = scheduleDate.getHours();
    const scheduleMinutes = scheduleDate.getMinutes();
    if (!Number.isNaN(scheduleHours) && !Number.isNaN(scheduleMinutes)) {
      return (
        <>
          {scheduleDate.getDate()}
          <TriningDate>
            {scheduleDate
              .toLocaleString('default', {
                month: 'short',
              })
              .toUpperCase()}
          </TriningDate>
        </>
      );
    }
    return <TriningDate>{schedule}</TriningDate>;
  };

  useEffect(() => {
    const isEllipsisActive = (element) => {
      return element.offsetHeight + 2 < element.scrollHeight;
    };

    const articlesTitle = document.querySelectorAll('.article-title');
    const renderTitleTooltip: string[] = [];
    articlesTitle.forEach((el) => {
      if (isEllipsisActive(el)) {
        renderTitleTooltip.push(el.innerText);
      }
    });
    setRenderTooltip(renderTitleTooltip);
  }, []);

  return (
    <Container>
      <GlobalWrapper>
        <TitleContainer>Resource Center</TitleContainer>
        <SubtitleContainer>
          Unlock the full potential of Pathful with our extensive library of user resources.
        </SubtitleContainer>
        <ArticlesContainer>
          {articles
            .flat()
            .slice(0, 3)
            .map((article, index) =>
              article.type === ArticleType.Material ? (
                <ArticleWrapper
                  isFirstArticle={index === 0}
                  href={`${PATHS.MATERIAL}/${transformToSlug(
                    article.attributes.Title.toLowerCase(),
                  )}`}
                >
                  <DataContainerArticle isFirstArticle={index === 0} className="data-container">
                    {index === 0 && (
                      <Tag isFirstArticle type={ArticleType.Material} position="relative">
                        Blog
                      </Tag>
                    )}
                    <ArticleTitleContainer>
                      <ArticleTitle className="article-title" isFirstArticle={index === 0}>
                        {article.attributes.Title}
                      </ArticleTitle>
                      {renderTooltip.includes(article.attributes.Title) && (
                        <ArticleTitleTooltip className="article-title-tooltip">
                          {article.attributes.Title}
                        </ArticleTitleTooltip>
                      )}
                    </ArticleTitleContainer>
                    {index === 0 && (
                      <ArticleSubtitle>{article.attributes.Subtitle}</ArticleSubtitle>
                    )}
                    <ArticleData>
                      <ArticlePublicationDate>
                        {article.attributes.Publication_Date.split(' ').map((date, index) =>
                          index === 0 ? `${date.slice(0, 3)} ` : `${date} `,
                        )}
                      </ArticlePublicationDate>
                      {index === 0 && (
                        <ArticleTimeToRead>{article.attributes.Time_To_Read}</ArticleTimeToRead>
                      )}
                    </ArticleData>
                  </DataContainerArticle>
                  <ImageContainer isFirstArticle={index === 0}>
                    {CMSParseImage(article.attributes.Small_Image)}
                    {index !== 0 && (
                      <Tag isFirstArticle={false} type={ArticleType.Material}>
                        Blog
                      </Tag>
                    )}
                  </ImageContainer>
                </ArticleWrapper>
              ) : (
                <ArticleWrapper
                  isFirstArticle={index === 0}
                  href={`${PATHS.TRAINING}/${transformToSlug(
                    article.attributes.Title.toLowerCase(),
                  )}`}
                >
                  <DataContainerArticle isFirstArticle={index === 0} className="data-container">
                    {index === 0 && (
                      <Tag isFirstArticle type={ArticleType.Training} position="relative">
                        Training
                      </Tag>
                    )}
                    <TrainingSchedule>
                      {handleSchedule(article.attributes.Schedule)}
                    </TrainingSchedule>
                    <ArticleTitleContainer>
                      <ArticleTitle className="article-title" isFirstArticle={index === 0}>
                        {article.attributes.Title}
                      </ArticleTitle>
                      {renderTooltip.includes(article.attributes.Title) && (
                        <ArticleTitleTooltip className="article-title-tooltip">
                          {article.attributes.Title}
                        </ArticleTitleTooltip>
                      )}
                    </ArticleTitleContainer>
                    {index === 0 && (
                      <ArticleSubtitle>{article.attributes.Subtitle}</ArticleSubtitle>
                    )}
                  </DataContainerArticle>
                  <ImageContainer isFirstArticle={index === 0}>
                    {CMSParseImage(article.attributes.Small_Image)}
                    {index !== 0 && (
                      <Tag isFirstArticle={false} type={ArticleType.Training}>
                        Training
                      </Tag>
                    )}
                  </ImageContainer>
                </ArticleWrapper>
              ),
            )}
        </ArticlesContainer>
      </GlobalWrapper>
    </Container>
  );
};

export default ResourcesCenterHero;
