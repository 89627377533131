import React, { SVGProps } from 'react';

const ArrowIcon = (props?: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10.194" height="6.543" {...props}>
      <path
        data-name="Path 1016"
        d="M9.934 6.361a.749.749 0 0 0 .078-1.057L5.665.26a.749.749 0 0 0-1.135 0L.182 5.304a.75.75 0 1 0 1.135.979l3.784-4.386 3.777 4.386a.749.749 0 0 0 1.056.078"
      />
    </svg>
  );
};

export default ArrowIcon;
