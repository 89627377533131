import React, { SVGProps } from 'react';

const CarrouselIcon = (props?: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" {...props}>
      <g id="arrow" transform="translate(1 1)">
        <g id="Ellipse_291" data-name="Ellipse 291" stroke="#fff" strokeWidth="1">
          <circle cx="20" cy="20" r="20" stroke="none" />
          <circle cx="20" cy="20" r="20.5" fill="none" />
        </g>
        <path
          id="Path_10922"
          data-name="Path 10922"
          d="M6.987,0,0,6.987l6.987,6.987"
          transform="translate(25.487 27.475) rotate(180)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
};

export default CarrouselIcon;
